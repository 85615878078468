<template>
  <div class="headers">
    <el-row class="contain">
      <el-col :span="12">
        <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/header/logo.png" class="logo"/>
      </el-col>
      <el-col :span="12">
        <el-menu router :default-active="activeIndex" @select='scrollToTop' class="el-menu" mode="horizontal">
<!--          <el-menu-item index="/home">首页</el-menu-item>-->
<!--          <el-menu-item index="/profession">专业</el-menu-item>-->
<!--          <el-menu-item index="/solution">解决方案</el-menu-item>-->
<!--          <el-menu-item index="/team">菁英团队</el-menu-item>-->
<!--          <el-menu-item index="/about">联系我们</el-menu-item>-->
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "headers",
  data() {
    return {
      activeIndex: '/home',
    }
  },
  watch: {
    $route: {
      handler() {
        this.activeIndex = this.$route.path;
        //深度监听，同时也可监听到param参数变化
      },
      deep: true,
    }
  },
  mounted() {
    this.activeIndex = this.$route.path;
  },
  methods: {
    scrollToTop() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="less">
.headers {
  .contain {
    width: 12.00rem;
    height: 0.90rem !important;

    .logo {
      width: 1.92rem;
      float: left;
      margin-left: 0;
      margin-top: 0.20rem;
    }

    .el-menu {
      background: transparent;
      border-bottom: 0;

      .el-menu-item {
        color: #fff;
        min-width: 1.2rem;
        font-size: 0.18rem;
        height: 0.90rem;
        width: 20%;
        padding: 0;
        line-height: 0.90rem;


        &:hover {
          background: rgb(0 0 0 / 10%);
          color: #ffffff;
        }

        &:focus {
          background: transparent;
          color: #ffffff;
        }

        &.is-active {
          border-bottom: 2px solid #ffffff;
        }
      }
    }
  }
}
</style>
