<template>
  <div class="footer-m">
    <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/other/logo.png" width="50%"/>
<!--    <div class="menus">
      <a @click="$router.push('/m/home')" class="m-m">首页</a>
      <a @click="$router.push('/m/profession')" class="m-m">专业</a>
      <a @click="$router.push('/m/solution')" class="m-m">解决方案</a>
      <a @click="$router.push('/m/team')" class="m-m">菁英团队</a>
      <a @click="$router.push('/m/about')" class="m-m">联系我们</a>
    </div>
&lt;!&ndash;    <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/other/wechat.png" width="34"/>&ndash;&gt;
    <div class="dt-1">
      <p class="txt-1">客服热线：010-53359933</p>
      <p class="txt-1">电子邮箱：zyt@sfdy.com</p>
      <p class="txt-1">北京市昌平区七北路42号院3号楼7层3单元708</p>
    </div>
    <el-row class="qr-code" :gutter="33">
      <el-col :span="12">
        <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/about/car.jpg" width="100%"/>
        <p class="txt-1">车主端</p>
      </el-col>
      <el-col :span="12">
        <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/h5/about/owner.jpg" width="100%"/>
        <p class="txt-1">货主端</p>
      </el-col>
    </el-row>-->
    <div class="line"></div>
    <div class="icp">
      <p class="txt-1">顺风大运（天津）科技有限责任公司</p>
      <p class="txt-1">津ICP备2024023362号-1</p>
<!--      <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
        <img src="../../img/gh.png" style="float:left;"/>
        <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备
          11010802033204号</p></a>-->
      <p class="txt-1"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "footers"
}
</script>

<style lang="less">
.footer-m {
  background: #202B3E;
  padding: 30px 0;
  text-align: center;

  .menus {
    margin-top: 35px;

    .m-m {
      display: block;
      text-decoration: none;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 22px;
      margin-bottom: 18px;
    }
  }

  .dt-1 {
    text-align: center;
    margin: 37px auto;

    .txt-1 {
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 20px;
    }
  }

  .qr-code {
    width: 80%;
    text-align: center;
    display: inline-block;
    margin: 0 auto;

    img {
      border-radius: 10px;
    }

    .txt-1 {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, .6);
      line-height: 20px;
    }
  }

  .line {
    height: 1px;
    background: rgba(255, 255, 255, .3);
    margin: 28px auto 12px auto;
  }

  .icp {
    .txt-1 {
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 255, 255, .5);
      line-height: 20px;
    }
  }
}
</style>
