<template>
  <div class="footers">
<!--    <el-row class="contain">
      <el-col :span="6" style="text-align: left">
        <img src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/header/logo.png" class="logo"/>
      </el-col>
      <el-col :span="3">
        <a @click="$router.push('/home')" class="link-tab">首页</a>
        <a @click="$router.push('/profession')" class="link-tab">专业</a>
        <a @click="$router.push('/solution')" class="link-tab">解决方案</a>
        <a @click="$router.push('/team')" class="link-tab">菁英团队</a>
        <a @click="$router.push('/about')" class="link-tab">联系我们</a>
      </el-col>
      <el-col :span="7" style="text-align: left">
        <img class='image2' src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/footer/wechat.png"/>
        <p class="introduce">客服热线：010-53359933</p>
        <p class="introduce">电子邮箱：zyt@sfdy.com</p>
        <p class="introduce">北京市昌平区七北路42号院3号楼7层3单元708</p>
      </el-col>
      <el-col :span="8">
        <el-row>
          <el-col :span="12">
            <img class='image0' src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/footer/car.png"/>
            <p class="code-title">车主端</p>
          </el-col>
          <el-col :span="12">
            <img class='image1' src="https://gwsite-1302681310.cos.ap-beijing.myqcloud.com/assets/footer/owner.png"/>
            <p class="code-title">货主端</p>
          </el-col>
        </el-row>
      </el-col>
    </el-row>-->
    <div class="icp-content">
      顺风大运（天津）科技有限责任公司
      <a style="text-decoration:none;" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">{{ icpCode }}</a>
      <!--      <div style="width:300px;margin:0 auto; padding:20px 0;">
              <a target="_blank" href=" " style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
                <img src="../img/gh.png" style="float:left;"/>
                <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备
                  11010802033204号</p></a>
            </div>-->
    </div>
  </div>
</template>

<script>
export default {
  name: "footers",
  data() {
    return {
      icpCode: '津ICP备2024023362号-1'
    }
  },
  mounted() {
    let host = window.location.host;
    console.log(window.location.host);
    /*if (host.indexOf('sfdy.com') != -1) {
      this.icpCode = '京ICP备2020036021号-1'
    } else if (host.indexOf('sfdy.cn') != -1) {
      this.icpCode = '京ICP备2020036021号-4'
    } else {
      this.icpCode = '京ICP备2020036021号'
    }*/
  }
}
</script>

<style lang="less">
.footers {

  .contain {
    color: #ffffff;
    border-bottom: 1px solid #ffffff;
    padding-top: 0.84rem;
    width: 13.00rem;
    padding-bottom: 0.60rem;

    .logo {
      width: 1.92rem;
      margin-top: 0.40rem;
    }

    .image0 {
      float: left;
      width: 1.19rem;
      height: 1.20rem;
    }

    .image1 {
      float: left;
      width: 1.19rem;
      height: 1.20rem;
    }

    .image2 {
      float: left;
      margin-top: 0.07rem;
      width: 0.34rem;
      height: 0.27rem;
    }

    .link-tab {
      display: block;
      cursor: pointer;
      color: #ffffff;
      text-align: left;
      line-height: 0.40rem;
      font-size: 0.16rem;
    }

    .introduce {
      float: left;
      width: 100%;
      text-align: left;
      font-size: 0.14rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.42rem;
    }

    .code-title {
      float: left;
      width: 1.19rem;
      font-size: 0.14rem;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 0.20rem;
      text-align: center;
      margin-top: 0.14rem;
    }
  }

  .icp-content {
    padding: 0.28rem 0;
    font-size: 0.16rem;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 0.22rem;
    text-align: center;

    a {
      color: #ffffff;
      margin-left: 0.05rem;
    }
  }
}
</style>
